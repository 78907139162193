import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const Education = () => {
    return (
        <Layout>
                         <Seo
      title="Éducation - BIKESAFE"
      description="Il n'y a encore pas si longtemps, la majorité des élèves se rendait à l'école à pied ou à vélo. Aujourd'hui, ils prennent souvent le bus ou sont conduits à l'école en voiture..."
    />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                            Éducation
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <h3>Prendre le bus, c'est bien. Circuler à vélo, c'est mieux !</h3>
                        <p className="flow-text">
                            Il n'y a encore pas si longtemps, la majorité des élèves se rendait à l'école à pied ou à vélo. Aujourd'hui, ils prennent souvent le bus ou sont conduits à l'école en voiture. Certains établissements scolaires et d'enseignement supérieur ont même interdit l'usage des vélos. Idée frustrante, non? Comment éviter cela et permettre aux élèves et étudiants de continuer à pratiquer une activité physique qui est si indispensable ?
                        </p>
                        <h3>Guide de stationnement de Bike Safe</h3>
                        <p className="flow-text">
                            Il est si facile de prévoir un système de stationnement pour vélos ! En effet, il suffit juste d'acheter un rack à vélos !
                        </p>
                        <p className="flow-text">
                            Il arrive parfois que cela requiert un peu plus de planification. Dans tous les cas, si vous souhaitez bénéficier du meilleur rapport qualité-prix tout en respectant votre budget, Bike Safe travaille avec les établissements scolaires et d'enseignement supérieur sur l'ensemble du territoire afin d'apporter des solutions aux problèmes de stationnement des vélos. Chez Bike Safe, nous connaissons les avantages de systèmes de parking à vélos de qualité mais nous savons également qu'il est tout aussi important d'offrir de bons conseils.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred"  src="../images/new-york.jpg" className="shadow mt-5 mb-2 fluid rounded" />
                        <p>
                            <em className="flow-text">« Je tiens à remercier tout le personnel de Bike Safe pour son service très performant et professionnel. Ils ont fait preuve de flexibilité et ont su prendre en compte les exigences conceptuelles de mon établissement scolaire. »
                                <strong> Paul Jordan, Directeur, Thames View Infant School, Dagenham</strong>.</em></p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center mt-5">
                        <h3>Identifier les besoins et les problèmes</h3>
                        <p className="flow-text">
                        Tenir compte de l'usage du cycliste actuel est une première étape importante pour déterminer vos besoins. La première méthode consiste à compter le nombre de vélos pendant trois journées types (exemple : journée ensoleillée). Lors du décompte des vélos, prenez note des points suivants :
                        </p>
                        <ul>
                            <li className="border p-2 shade-on-hover">Les vélos sont-ils stationnés de façon à être facilement accessibles ?</li>
                            <li className="border p-2 shade-on-hover">Les vélos sont-ils stationnés aux endroits prévus à cet effet et non aux arbres, aux poteaux, aux bancs, etc. ?</li>
                        </ul>
                    </Col>
                    <Col sm="12" className="text-center">
                        <h3>Choisir les bons endroits</h3>
                        <p className="flow-text">
                        Les racks à vélos se trouvent souvent derrière les bâtiments ou cachés sur le côté de sorte qu'ils ne se voient pas. Ceci décourage l'utilisation des racks car ce n'est pas pratique pour le cycliste. Les ranges vélos qui sont situés loin du regard des passants, offrent aux voleurs plus de temps et de discrétion pour voler sans être dérangés. Il convient donc de placer des racks à vélos à proximité d'une entrée principale, qui est un endroit à la fois plus pratique et plus visible pour les cyclistes. Une meilleure visibilité des racks se traduit par une réduction des vols et cela incite par la même occasion les gens à utiliser plus le vélo.
                        </p>
                        <h3>Envisager des solutions uniquese</h3>
                        <p className="flow-text">
                        Le manque de protection pour les vélos constitue l'une des principales raisons pour laquelle les étudiants ne circulent pas à vélo. Les abris et parcs à vélos offrent un plus haut niveau de protection que les racks non couverts. Ainsi, le toit d'un abri permet de protéger les vélos et leurs propriétaires de la pluie et de la neige et offre une plus grande sécurité.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred"  src="../images/verticals.jpg" className="shadow mt-5 mb-2 fluid rounded" />
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center mt-5">
                        <h3>Problèmes de sécurité</h3>
                        <p className="flow-text">
                        Les différents modèles de racks à vélos et les différentes méthodes d'installation offrent différents niveaux de sécurité. Lors du choix d'un rack pour votre établissement scolaire ou d'enseignement supérieur, vous souhaiterez probablement tenir compte des points suivants :
                        </p>
                        <ul>
                            <li className="border p-2 shade-on-hover">À quel point la sécurité est-elle importante ? Y-a-t-il des problèmes de vols dans votre établissement ?</li>
                            <li className="border p-2 shade-on-hover">Combien de vélos devront être stationnés et quel est l'espace disponible ?</li>
                            <li className="border p-2 shade-on-hover">Sur quel type de surface le rack sera-t-il installé ? Asphalte, herbe, pavés, béton ?</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default Education;